import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, size, style }) => (
  <SvgIcon
    viewBox='0 0 17 17'
    style={{
      height: size || '17px',
      width: size || '17px',
      ...style,
    }}
  >
    <g
      id='Desktop'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='square'
    >
      <g
        id='job-trough-a-share-link'
        transform='translate(-1131.000000, -443.000000)'
        stroke={color || '#0041E5'}
        strokeWidth='1.5'
      >
        <g id='Group-6' transform='translate(432.000000, 256.000000)'>
          <g id='Group-24' transform='translate(42.000000, 174.000000)'>
            <g id='Group-19-Copy-3' transform='translate(642.000000, 2.000000)'>
              <g id='Group-21' transform='translate(16.000000, 7.000000)'>
                <g id='share' transform='translate(0.500000, 5.000000)'>
                  <circle id='Oval' cx='11.25' cy='2.25' r='2.25'></circle>
                  <circle id='Oval' cx='2.25' cy='7.5' r='2.25'></circle>
                  <circle id='Oval' cx='11.25' cy='12.75' r='2.25'></circle>
                  <line
                    x1='4.1925'
                    y1='8.6325'
                    x2='9.315'
                    y2='11.6175'
                    id='Path'
                  ></line>
                  <line
                    x1='9.3075'
                    y1='3.3825'
                    x2='4.1925'
                    y2='6.3675'
                    id='Path'
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
